<template>
    <ContentContainer
        id="drawer"
        data-cy-key="daily-chat-drawer"
        size="xlarge"
        class="drawer"
        :class="{ 'drawer--expanded': isExpanded }"
        role="dialog"
        aria-labelledby="drawerTitle"
        aria-describedby="drawerDesc"
    >
        <div class="drawer-bg" :class="{ 'drawer-bg--expanded': isExpanded }"></div>
        <div ref="drawer" class="drawer-top" :class="{ 'drawer-top--expanded': isExpanded }">
            <div v-show="!isExpanded" class="text-area top-text-area" data-cy="drawer-top">
                <span id="drawerTitle">{{ $t('dailyChatDrawer.title') }}</span>
            </div>

            <div class="join-area top-join-area">
                <div v-if="!isExpanded" class="dn db-m">
                    <AppButton
                        :variant="$options.ButtonVariant.outline"
                        :color="$options.ButtonColor.white"
                        :size="$options.ButtonSize.medium"
                        data-cy="drawer-subscribe-button"
                        @click="toggle"
                    >
                        {{ $t('dailyChatDrawer.subscribeButton') }}
                    </AppButton>
                </div>
                <button
                    class="join-area-toggle"
                    data-cy="drawer-join-toggle"
                    aria-haspopup="true"
                    aria-controls="drawer"
                    :aria-expanded="isExpanded.toString()"
                    @click="toggle"
                >
                    <Icon
                        name="daily-drawer-chevron-up"
                        class="chevron-icon"
                        :class="{ 'chevron-icon--down': isExpanded }"
                        :height="null"
                        :width="null"
                    />
                    <Icon
                        v-show="isExpanded"
                        name="daily-drawer-close"
                        class="close-icon"
                        :height="null"
                        :width="null"
                    />
                </button>
            </div>
        </div>

        <div class="drawer-content" :class="{ 'drawer-content--expanded': isExpanded }">
            <div class="text-area">
                <DailyChatLogo class="drawer-dc-logo" variant="secondary" />
                <div class="text-area-subtitle">{{ $t('dailyChatDrawer.subtitle') }}</div>
                <div id="drawerDesc" class="text-area-info">{{ $t('dailyChatDrawer.info') }}</div>
            </div>
            <div class="join-area">
                <EmailSubmitForm
                    :is-loading="isLoading"
                    :autofocus="false"
                    :input-label="$t('emailSubmitForm.inputLabel')"
                    :variant="$options.EmailSubmitFormVariant.outline"
                    class="join-area-form"
                    @submit="onSubmit"
                />
            </div>
        </div>
    </ContentContainer>
</template>

<script>
import throttle from 'lodash.throttle';
import AppButton from '@/components/AppButton';
import { LoginSource, EmailSubmitFormVariant } from '@/enums';
import { ButtonVariant, ButtonSize, ButtonColor } from '@/enums/buttons';
import ContentContainer from '@/components/ContentContainer';
import Icon from '@/components/Icon';
import EmailSubmitForm from '@/components/forms/EmailSubmitForm';
import DailyChatLogo from '@/components/daily-chat/DailyChatLogo';

export default {
    name: 'DailyChatDrawer',
    components: {
        ContentContainer,
        EmailSubmitForm,
        DailyChatLogo,
        AppButton,
        Icon,
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    watch: {
        errors: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value && value.length > 0) {
                    this.isLoading = false;
                }
            },
        },
    },
    mounted() {
        this.addScrollListener();
    },
    beforeDestroy() {
        this.removeScrollListener();
    },
    created() {
        this.$options.ButtonVariant = ButtonVariant;
        this.$options.ButtonColor = ButtonColor;
        this.$options.ButtonSize = ButtonSize;
        this.$options.EmailSubmitFormVariant = EmailSubmitFormVariant;
    },
    methods: {
        toggle() {
            this.$emit('expand', !this.isExpanded);
        },
        onSubmit({ email }) {
            if (email) {
                this.isLoading = true;
                this.$emit('dailyChatSubscribe', {
                    email,
                    source: LoginSource.DailyChatDrawer,
                });
            }
        },
        addScrollListener() {
            document.addEventListener('scroll', this.handleScroll, true);
        },
        removeScrollListener() {
            document.removeEventListener('scroll', this.handleScroll, true);
        },
        handleScroll: throttle(function () {
            const convoListTitle = document.getElementById('other_chats');
            const clientRect = convoListTitle.getBoundingClientRect();
            const drawerElement = this.$refs.drawer;
            const computedStyle = window.getComputedStyle(drawerElement);
            const fontSize = computedStyle.fontSize;
            const remHeight = window.innerHeight / parseInt(fontSize);

            const shouldExpand = clientRect.top + remHeight < window.innerHeight;

            if (shouldExpand) {
                this.$emit('expand', true);
                this.removeScrollListener();
            }
        }, 100),
    },
};
</script>

<style scoped lang="scss">
$drawer-bg-color: #7e3194 !default;

.drawer {
    font-family: $sans-serif;
    position: fixed;
    overflow: hidden;
    width: 100%;
    bottom: 0;
    left: 0;
    transform: translateY(calc(100% - 6rem));
    transition: transform 0.2s ease-out;
    height: max-content;

    @include media-query(medium) {
        min-height: 33rem;
    }

    &--expanded {
        transform: translateY(0);
        flex-direction: column;

        @include media-query(medium) {
            flex-direction: row;
        }
    }
}

.drawer-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: map-get($newPalette, red, 300) url('../assets/images/yg-background.svg') no-repeat center bottom;
    background-size: cover;
    transition: transform 0.2s ease-out;
    transform: translateY(calc(-100% + 6rem));
    opacity: 0.98;

    &--expanded {
        transform: translateY(0);
    }
}

.drawer-top {
    display: flex;
    justify-content: space-between;
    height: 6rem;

    &--expanded {
        height: 0;
    }
}

.drawer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;
    transition: none;
    opacity: 0;

    &--expanded {
        transition: opacity 0.2s ease-out;
        opacity: 1;
    }

    @include media-query(medium) {
        align-items: flex-start;
        flex-direction: row;
    }
}

.text-area {
    display: flex;
    align-items: center;
    line-height: 1.37;
    color: $white;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;

    @include media-query(medium) {
        text-align: left;
        width: 54rem;
        padding-right: 2rem;
        font-size: $large-font-size;
    }

    @include media-query(large) {
        padding-top: 6.5rem;
    }

    .drawer--expanded & {
        padding-top: 2rem;

        @include media-query(medium) {
            padding-top: 5.5rem;
            align-items: flex-start;
        }
    }
}

.top-text-area {
    padding-top: 0;
    flex-direction: row;
}

.drawer-dc-logo {
    display: inline;
    margin-bottom: 2.5rem;
    color: $color-white;
    font-size: 3rem;

    @include media-query(medium) {
        font-size: 4.8rem;
    }
}

.text-area-subtitle {
    font-weight: $font-weight-semibold;
    margin-bottom: 2rem;
    font-size: 2rem;

    @include media-query(medium) {
        font-size: 2.4rem;
    }
}

.text-area-info {
    font-size: $small-font-size;
    font-weight: $font-weight-medium;

    @include media-query(medium) {
        font-size: $large-font-size;
    }
}

.join-area {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    padding-right: 0;

    .drawer--expanded & {
        align-items: start;
    }
}

.top-join-area {
    justify-content: center;

    .drawer--expanded & {
        width: 100%;
        justify-content: flex-end;
    }
}

.join-area-form {
    padding-top: 3rem;
    color: map-get($newPalette, white);
    @include media-query(medium) {
        padding-top: 7rem;
        width: 37rem;
        padding-right: 4rem;
    }
}

.join-area-toggle {
    display: flex;
    margin-left: 0;
    cursor: pointer;
    padding: 10px;
    border: none;
    background-color: transparent;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    @include media-query(medium) {
        margin-left: 5rem;
    }

    .drawer--expanded & {
        margin-top: 0;
        position: absolute;
        top: 2rem;

        &::before {
            display: none;
        }

        @include media-query(medium) {
            position: relative;
            margin-right: 0rem;
        }
    }
}

.chevron-icon {
    width: 1.5rem;
    height: 0.8rem;
    color: $white;

    @include media-query(medium) {
        width: 2rem;
        height: 1.1rem;
    }

    &--down {
        display: none;

        @include media-query(medium) {
            display: block;
            transform: rotate(180deg);
        }
    }
}

.close-icon {
    width: 1.5rem;
    height: 1.5rem;

    @include media-query(medium) {
        display: none;
    }
}
</style>
