<script>
import { mapState, mapActions } from 'vuex';
import { auth as AuthService } from '@/auth';

export default {
    computed: {
        ...mapState('chatController', {
            chatControllerIsChatOpen: 'isChatOpen',
        }),
    },
    methods: {
        ...mapActions('chatController', {
            toggleChatControllerVisibility: 'toggleVisibility',
        }),
        $_pageMixin_toggleChatVisibility(isVisible) {
            this.toggleChatControllerVisibility(isVisible);

            if (isVisible && this.chatControllerIsChatOpen) {
                this.$chatWidget.openChat();
            } else if (!isVisible) {
                this.$chatWidget.hideChat();
            }
        },
        async $_pageMixin_ygdGuard() {
            return await AuthService.ygdGuard({
                store: this.$store,
                redirect: (path, query) => this.$router.push({ path, query }),
                route: this.$route,
                $logger: this.$logger,
                $sentry: this.$sentry,
            });
        },
    },
};
</script>
