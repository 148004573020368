<template>
    <div v-editable="page" class="page-default-pt" :class="{ 'page-container': !fullWidthLayout }">
        <main class="main-section" :class="{ 'main-section--full-width': fullWidthLayout }">
            <WidgetContainer
                :widgets="page.widgetsData"
                :widget-ids="page.sections.main.widgets"
                @loadMore="onLoadMore"
                @openConvo="onCardClick"
            />
            <DailyChatDrawer
                v-if="showDailyChatDrawer"
                :is-expanded="isDailyChatDrawerExpanded"
                :errors="errors"
                @dailyChatSubscribe="onDailyChatSubscribe"
                @expand="isDailyChatDrawerExpanded = $event"
            />
        </main>
        <aside v-if="!fullWidthLayout" class="right-section">
            <WidgetContainer
                :widgets="page.widgetsData"
                :widget-ids="page.sections.right.widgets"
                @loadMore="onLoadMore"
                @openConvo="onCardClick"
            />
        </aside>
    </div>
</template>

<script>
import throttle from 'lodash.throttle';
import WidgetContainer from '@/components/WidgetContainer';
import ContentService from '@/services/content.service';
import RouterService from '@/services/router.service';
import AbTestService from '@/services/ab-test.service';
import { ErrorCodes, QueryParam, ModalType, AppAction, StorySlug, Layout } from '@/enums';
import { mapActions, mapState, mapGetters } from 'vuex';
import { browser } from '@inconvo/utils';
import config from '@/config';
import { auth as AuthService } from '@/auth';
import PageMixin from '@/mixins/PageMixin';
import { ChatWidgetChannelFactory } from '@/models/factories';
import DailyChatDrawer from '@/components/DailyChatDrawer';

export default {
    components: {
        WidgetContainer,
        DailyChatDrawer,
    },
    mixins: [PageMixin],
    async asyncData(context) {
        try {
            const abTestService = new AbTestService(context.app.$cookies);
            const contentService = new ContentService(
                context.app.$storyapi,
                abTestService,
                context.$logger,
                context.app.i18n.locale,
            );
            let storySlug = StorySlug.Convos;
            let sections = ['main', 'right'];

            if (config.featureToggle.useDailyChat || context.query.dailyChat) {
                storySlug = StorySlug.ConvoV3;
                sections = ['main'];
            }

            const story = await contentService.getStory({
                story: storySlug,
                version: context.app.$storyblokHelper.version,
                locale: context.app.i18n.locale,
                hasFallback: true,
            });

            const page = await contentService.getPageContent(story, sections);

            if (page.errors.length) {
                page.errors.map((pageError) =>
                    context.$logger.error({
                        msg: 'error.getPageContent Index',
                        pageError,
                        errorCode: ErrorCodes.FailedToGetPageContent,
                    }),
                );
                context.$notifications.error(context.app.i18n.t('notification.pageLoadFailed'), { dismissable: true });
            }

            const layout = story.content.component || '';

            return {
                page,
                layout,
            };
        } catch (error) {
            context.$logger.error({ msg: 'error.convosContent', error });
            context.$notifications.error(context.app.i18n.t('notification.pageLoadFailed'), { dismissable: true });
        }
    },
    data() {
        return {
            page: { widgetsData: {}, sections: { main: { widgets: [] }, right: { widgets: [] } } },
            layout: '',
            action: this.$queryParams(QueryParam.Action),
            userId: this.$queryParams(QueryParam.RecontactId),
            signInCode: this.$queryParams(QueryParam.SignInCode),
            referral: this.$queryParams(QueryParam.Referral),
            redirectPath: this.$queryParams(QueryParam.RedirectPath),
            embedded: this.$queryParams(QueryParam.Embedded) === '1',
            isDailyChatDrawerExpanded: false,
        };
    },
    computed: {
        ...mapState('main', ['locale', 'isDailyChatEnabled']),
        ...mapState('auth', ['isLoggedIn', 'authRefreshed', 'user', 'isKnownUser', 'errors']),
        ...mapGetters('auth', ['subscriberId']),
        ...mapState('channels', ['activeChannel']),
        ...mapGetters('chatController', {
            chatControllerActiveChannel: 'activeChannel',
        }),
        ...mapState('chatController', {
            channelList: 'channelList',
        }),
        fullWidthLayout() {
            return this.layout === Layout.FullWidth;
        },
        showDailyChatDrawer() {
            return (
                this.isDailyChatEnabled &&
                !this.embedded &&
                this.authRefreshed &&
                !this.isLoggedIn &&
                !this.isKnownUser &&
                !this.subscriberId
            );
        },
    },
    watch: {
        isLoggedIn: {
            immediate: true,
            handler(isLoggedIn) {
                if (isLoggedIn) {
                    this.getProfile();
                }
            },
        },
        $route() {
            if (this.$route.query.action === AppAction.VerifyUser) {
                this.verifyUserAndShowModal({ redirectPath: this.$route.query.redirect_path });
            }
        },
    },
    mounted() {
        this.$_pageMixin_toggleChatVisibility(true);
        const abTestService = new AbTestService(this.$cookies);
        this.contentService = new ContentService(this.$storyapi, abTestService, this.$logger, this.locale);
        this.$storyblokHelper.addEventListener('input', this.onStoryblokInput);

        if (this.authRefreshed) {
            this.prepare();
        } else {
            const unwatch = this.$watch('authRefreshed', () => {
                unwatch();
                this.prepare();
            });
        }
        if (this.userId !== '') {
            this.setUserId(this.userId);
            this.setKnownUserFlag();
        }
        this.setReferral(this.referral);
    },
    beforeDestroy() {
        this.$storyblokHelper.removeEventListener('input', this.onStoryblokInput);
    },
    methods: {
        ...mapActions('main', ['toggleModal', 'showFullPageLoader']),
        ...mapActions('auth', {
            getAuthData: 'getAuthData',
            setKnownUserFlag: 'setKnownUserFlag',
            setUserEmail: 'setUserEmail',
            setReferral: 'setReferral',
            setUserId: 'setUserId',
            validateRecontactToken: 'validateRecontactToken',
            handleDailyChatSubscribe: 'onDailyChatSubscribe',
        }),
        ...mapActions('yougovDirect', ['getProfile']),
        ...mapActions('channels', ['getChannel']),
        ...mapActions('chatController', {
            chatControllerStartChat: 'startChat',
        }),
        async onLoadMore(widgetId) {
            const widget = this.page.widgetsData[widgetId];
            widget.page = widget.page + 1;
            const items = await this.contentService.getMore(widget);
            widget.items.push(...items);
        },
        onCardClick: throttle(
            function (data) {
                if (!this.authRefreshed) {
                    return;
                }

                if (!this.showDailyChatDrawer) {
                    this.startChat(data);
                } else {
                    this.isDailyChatDrawerExpanded = true;
                }
            },
            800,
            { trailing: false },
        ),
        async startChat(data) {
            if (data && data.channel) {
                if (config.featureToggle.useRecontactToken && this.$route.query.recontact_token) {
                    await this.validateRecontactToken(this.$route.query.recontact_token);
                }
                const authData = await this.getAuthData();

                if (data.userSource && authData.referral) {
                    const referral = JSON.parse(authData.referral);
                    referral.utm_source = data.userSource;
                    authData.referral = JSON.stringify(referral);
                }

                this.$chatWidget.setAuthData(authData);
                const chatWidgetChannel = ChatWidgetChannelFactory.make(data.channel);

                let activeChannel;
                if (chatWidgetChannel.isValid()) {
                    activeChannel = chatWidgetChannel;
                } else {
                    await this.getChannel(data.channel.slug);
                    activeChannel = this.activeChannel;
                }

                const currentActiveConvoId = this.chatControllerActiveChannel
                    ? this.chatControllerActiveChannel.activeConvoId
                    : undefined;

                activeChannel.activeConvoId = data.id;

                this.chatControllerStartChat(activeChannel);

                const options = {
                    channel: this.chatControllerActiveChannel,
                    convo: +data.id,
                };
                if (this.chatControllerActiveChannel.activeConvoId) {
                    options.activeConvoId = currentActiveConvoId;
                }
                this.$chatWidget.startChat(options);
            }
        },
        prepare() {
            const showTask = this.$route.query.taskId && this.$route.query.recontactId;
            /*
            if the user navigates in the history and there is no modal related parameter
            in the url it should close the modal
            */
            const queryAction = this.$route.query.action;
            const shouldCloseModal = !queryAction && !showTask;

            if (showTask) {
                this.showTaskModal(this.$route.query.taskId, this.$route.query.recontactId);
            } else if (queryAction === AppAction.Subscribe) {
                if (!this.subscriberId && !this.isLoggedIn) {
                    this.toggleModal({
                        type: ModalType.Subscribe,
                        payload: {
                            redirectPath: this.redirectPath,
                            referral: this.referral,
                            closable: true,
                            backdropStyle: 'solid',
                        },
                    });
                } else {
                    browser.replaceHistoryState('action');
                }
            } else if (queryAction === AppAction.VerifyUser) {
                this.verifyUserAndShowModal({ redirectPath: this.redirectPath });
            }

            if (!this.isLoggedIn) {
                if (queryAction === AppAction.Signup || queryAction === AppAction.SignupNotClosable) {
                    this.toggleModal({
                        type: ModalType.LoginEmail,
                        payload: {
                            redirectPath: RouterService.getRedirectPath(this.$route),
                            referral: this.referral,
                            closable: queryAction === AppAction.Signup,
                            backdropStyle: 'solid',
                        },
                    });
                }

                if (this.signInCode.length) {
                    this.toggleModal({ type: ModalType.AuthChallenge, payload: { signInCode: this.signInCode } });
                } else if (shouldCloseModal) {
                    this.toggleModal();
                }
            }
        },
        async onStoryblokInput(event) {
            if (event.story.id === this.page.id) {
                this.page = await this.contentService.getPageContent(event.story, ['main', 'right']);
            }
        },
        verifyUserAndShowModal({ redirectPath }) {
            const id = this.subscriberId || this.userId;
            AuthService.verifyUserAndShowModal(this.$store.dispatch, this.$router, {
                id,
                forceEmail: false,
                redirectPath,
                referral: this.referral,
                locale: this.locale,
            });
        },
        async onDailyChatSubscribe({ email, source }) {
            const data = {
                email,
                redirectPath: this.redirectPath,
                referral: this.referral,
                recaptchaToken: await AuthService.getRecaptchaToken(window.grecaptcha),
                source,
                metadata: {
                    utmCampaign: this.$queryParams(QueryParam.utmCampaign),
                    utmSource: this.$queryParams(QueryParam.utmSource),
                },
            };
            await this.handleDailyChatSubscribe(data);
        },
    },
};
</script>

<style lang="scss" scoped>
.page-container {
    height: 100%;

    @include media-query(medium) {
        display: flex;
    }
}

.main-section {
    flex: 1 1 70%;
    padding-bottom: 2.6rem;

    @include media-query(medium) {
        padding-bottom: 2rem;
        padding-right: 20px;
    }

    &--full-width {
        @include media-query(medium) {
            padding-right: 0;
        }
    }
}

.right-section {
    flex: 1 1 30%;

    @include media-query(medium) {
        padding-left: 20px;
    }
}
</style>
