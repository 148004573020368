<template>
    <div>
        <component
            :is="widgets[id].component"
            v-for="id in widgetIds"
            :key="id"
            :content-size="$options.ContentSize.XLarge"
            :data="widgets[id]"
            @loadMore="$emit('loadMore', $event)"
            @click="$emit('click', $event)"
            @openConvo="$emit('openConvo', $event)"
        />
    </div>
</template>

<script>
import { ContentSize } from '@/enums';

export default {
    props: {
        widgets: {
            type: Object,
            required: true,
        },
        widgetIds: {
            type: Array,
            required: true,
        },
    },
    created() {
        this.$options.ContentSize = ContentSize;
    },
};
</script>
